import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Button from '@components/partials/Button';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { setShowLoginPopup } from '../../../store/auth/authActions';
import { useDispatch } from 'react-redux';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import aboutUsImg1 from '@assets/pictures/about_us_1.jpeg';
import aboutUsImg2 from '@assets/pictures/about_us_2.jpeg';
import aboutUsImg3 from '@assets/pictures/about_us_3.jpeg';
import aboutUsImg4 from '@assets/pictures/about_us_4.jpeg';
import aboutUsImg5 from '@assets/pictures/about_us_5.jpeg';
import aboutUsImg6 from '@assets/pictures/about_us_6.jpeg';
import aboutUsMap from '@assets/pictures/about_us_new_map.jpeg';

const AboutUsHeroText = (props) => {
  const { t, classes = '' } = props;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [register, setRegister] = useState(false);

  const dispatch = useDispatch();
  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };

  return (
    <>
      <section className={`container-fluid about_us_hero_text ${classes}`}>
        <div className="row">
          <article className="col-lg-10 col-xl-12 mx-auto">
            <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} />
            <CenteredTitle firstHalf={t('About Us')} secondHalf={''} />
            <div className="about_us_align">
              <p
                className={`about_us_hero_text_synopsis ${isMobile ? 'mb-3' : ''}`}
              >
                {/* {t('AboutUs.headerContent')} */}
                {/* STAR CINEMAS IS OPERATED BY PHARS FILM COMPANY. */}
                Cinepolis Cinemas is the UAE's premier theatre chain, with more than 100
                screens in all over UAE, showcasing a wide range of films from
                Hollywood to Bollywood, as well as local Emirati films, and films
                from around the world, with contemporary food and beverage services.
              </p>
              <p
                className={`about_us_hero_text_synopsis ${isMobile ? 'mb-3' : ''}`}
              >
                Cinepolis Cinemas is committed to use the power of entertainment to
                create family-oriented experiences and destinations that are
                innovative, compelling and commerically viable.
              </p>
              <p
                className={`about_us_hero_text_synopsis ${isMobile ? 'mb-3' : ''}`}
              >
                Cinepolis Cinemas is operated by PHF (Phars Film), which has started in
                1964 and has since become theMiddle East’s market leader in film
                distribution and cinema exhibition.
              </p>
              <p
                className={`about_us_hero_text_synopsis ${isMobile ? 'mb-3' : ''}`}
              >
                PHF unequaled distribution network and stakeholder partnerships now
                oversee the regional distribution and screening of over 200 films
                each year.
              </p>
              {/* <article className="about-us-map">
                <div style={{ marginBottom: '10px' }}>
                  <img src={aboutUsMap}></img>
                </div>
              </article> */}
              <article>
                <div>
                  <span>
                    <img src={aboutUsImg1} height="200px" />
                  </span>
                  <span>
                    <img src={aboutUsImg2} height="200px" />
                  </span>
                  <span>
                    <img src={aboutUsImg3} height="200px" />
                  </span>
                </div>
                <div>
                  <span>
                    <img src={aboutUsImg4} height="200px" />
                  </span>
                  <span>
                    <img src={aboutUsImg5} height="200px" />
                  </span>
                  <span>
                    <img src={aboutUsImg6} height="200px" />
                  </span>
                </div>
              </article>
              {/* <p className="about_us_hero_text_movie_details">
                {t('AboutUs.loginMessage')}
              </p> */}
              {/* <Button
                text={t(`header-footer-menu.Sign in`)}
                classes=""
                size="lg"
                onClick={() => handleLoginPopup(true)}
              /> */}
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHeroText);
