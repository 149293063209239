export const API_VERSION = 'v1.0';
const HOST_URL = `playcinema.brij.tech`;
const LIVEBASEURL = `https://playcinema-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL = window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = BASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const PLAYCINEMA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImQ0ZWQzZWNkLTdiMzUtNGIyNy1hMWJmLTE3NDg5MWNjZjRkYSIsImlhdCI6MTcyODkxMzkyNn0.TshgeOg3KdeCiinERZYxdKXtzWEihUCoNS9jXLJczPc`;
export const TOKEN = PLAYCINEMA_TOKEN;
